import React from "react" 
import ImageMeta from "../components/ImageMeta"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from '@bit/azheng.joshua-tree.button';
import PlayerTest from "../components/reactPlayerTest"
import ResponsivePlayer from "../components/ResponsivePlayer"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import CursorOverContainer from "../components/cursor/CursorOverContainer"

import fbRecommendations from "../images/fb-recommendations.svg"
import stars from "../images/stars.svg"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';

const SearchResultsPage = ({ data, location }) => {

    function createMarkup(text) {
        return {__html: text};
      }

      const siteMetadata = data.site.siteMetadata;



  if (location.state && location.state.suggestions && location.state.searchInput && location.state.searchTime) {
    const results = location.state.suggestions;
    const input = location.state.searchInput;
    const searchTime = location.state.searchTime;
    const searchResults = Object.keys(results).map(function(key) {

        if (key !== "key") {

            // dangerouslySetInnerHTML is used here as work-around to render <sup> tags...
            let heading = createMarkup(results[key].searchHeading.replace(/[™®©]/g, '<sup>$&</sup>'))
            let paragraph = createMarkup(results[key].metaDescription.replace(/[™®©]/g, '<sup>$&</sup>'));
            let url = results[key].url || results[key].title
            // url = url.toLowerCase();
            if (url.charAt(0) !== "/") url = "/" + url;
            
            return (
                <div key={url} className="single-result">
                    <Link
                    to={url}
                >
                <h6 className="modified-h6" dangerouslySetInnerHTML={heading}></h6>
                </Link>
                <p dangerouslySetInnerHTML={paragraph}></p>
                <Link
                    to={url}
                >{`${siteMetadata.siteUrl}${url}`}</Link>
                </div>
            )
        }
    })

    var resultsLength = Object.keys(results).length;

    return (
        <SharedStateProvider>
        <Layout language="en">
  <SEO title="Search Results" description="Search results" robots="noindex" />
            <section className="com-content search-results-page">
            <div className="container">
                <div className="columns">
                <div className="column"></div>
                <div className="column is-20">
                    <h2>Search Results</h2>
                    <h7>
                    {resultsLength} result{resultsLength === 1 ? "" : "s"} for "{input}" ({searchTime} seconds)
                    </h7>
                    {resultsLength > 0 && <div className="search-results-wrapper">{searchResults}</div>}
                </div>
                <div className="column"></div>
                </div>
            </div>
            </section>
        </Layout>
        </SharedStateProvider>
    )      
  } else {
      return (
        <SharedStateProvider>
        <Layout language="en">
  <SEO title="Search Results" description="Search results" robots="noindex" />
            <section className="com-content">
            <div className="container">
                <div className="columns">
                <div className="column"></div>
                <div className="column is-20">
                    <h2>Search Results</h2>
                    <h7>
                    0 results found
                    </h7>
                </div>
                <div className="column"></div>
                </div>
            </div>
            </section>
        </Layout>
        </SharedStateProvider>
      )
  }

}

export const pageQuery = graphql`
query searchQuery {
  site {
    siteMetadata {
      title
      description
      siteUrl
    }
  }
}
`

export default SearchResultsPage
